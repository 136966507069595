import React from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/app";
import "firebase/database";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Avatar from "react-avatar-edit";

class ProfileSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: this.props.currentUser.displayName
        ? this.props.currentUser.displayName
        : "",
      email: this.props.currentUser.email ? this.props.currentUser.email : "",
      showChangeAvatarModal: false,
      preview: null,
      avatarSrc: null,
      updatedAvatar: "",
    };

    this.onChangeText = this.onChangeText.bind(this);
    this.onAvatarCrop = this.onAvatarCrop.bind(this);
    this.onAvatarClose = this.onAvatarClose.bind(this);
  }

  onChangeText = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  onUpdateAccount = async () => {
    const { displayName, email } = this.state;

    if (displayName === "" || email === "") {
      toast.error("Sorry, name and email cannot be empty.", {
        autoClose: 4000,
      });
      return;
    }

    await firebase
      .database()
      .ref("users")
      .child(this.props.currentUser.uid)
      .update({
        displayName: displayName,
        email: email,
      });

    toast.success("Your info is saved.", {
      autoClose: 3000,
    });
  };

  handleModalClose = () => {
    this.setState({ showChangeAvatarModal: false });
  };

  onShowChangeAvatarModal = () => {
    this.setState({ showChangeAvatarModal: true });
  };

  onAvatarClose() {
    this.setState({ preview: null });
  }

  onAvatarCrop(preview) {
    // console.log("crop preview: ", preview);
    this.setState({ preview });
  }

  onImageLoad(image) {
    // console.log("image: ", image.target.files[0]);
  }

  handleUploadImage = async () => {
    const { preview } = this.state;
    const { currentUser } = this.props;
    var storageRef = await firebase.storage().ref(currentUser.uid);
    var avatarName = "avatar" + Date();
    var fileRef = storageRef
      .child("account")
      .child("personal")
      .child(avatarName);
    var type = preview.split(";")[0].split(":")[1];

    var uploadedFile = await fileRef.putString(
      preview.split(",")[1],
      "base64",
      {
        contentType: type,
      }
    );
    var fileURL = await uploadedFile.ref.getDownloadURL();
    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .update({ avatar: fileURL });
    this.setState({ updatedAvatar: fileURL, showChangeAvatarModal: false });
  };

  render() {
    const {
      displayName,
      email,
      showChangeAvatarModal,
      updatedAvatar,
    } = this.state;

    const { currentUser } = this.props;

    return (
      <div className="col-sm-9">
        <Modal
          show={showChangeAvatarModal}
          onHide={this.handleModalClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Update your avatar</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Avatar
              width={"100%"}
              height={300}
              onCrop={this.onAvatarCrop}
              onClose={this.onAvatarClose}
              src={this.state.avatarSrc}
              label="Choose a photo"
              onImageLoad={this.onImageLoad}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary px-5" onClick={this.handleUploadImage}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="border">
          <h3 className="mt-2" style={{ fontWeight: 600 }}>
            Edit Profile
          </h3>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <>
                <Form.Label>Change Avatar</Form.Label>
                {"  "}
                <span className="text-muted">
                  (max size 2MB, ratio 1:1, png/jpeg only)
                </span>
                <div className="text-left">
                  <img
                    src={
                      updatedAvatar === "" ? currentUser.avatar : updatedAvatar
                    }
                    className="avatar img-circle img-thumbnail"
                    alt="avatar"
                    onClick={this.onShowChangeAvatarModal}
                  />
                </div>
              </>
              <Form>
                <Form.Group controlId="displayName">
                  <Form.Label>Display Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="John Doe"
                    value={displayName}
                    onChange={this.onChangeText}
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="john@doe.com"
                    value={email}
                    onChange={this.onChangeText}
                  />
                </Form.Group>
              </Form>
              <div className="text-right border-less">
                <div className="col-12">
                  <button
                    className="btn btn-lg btn-primary mt-4"
                    onClick={this.onUpdateAccount}
                  >
                    <i className="fas fa-save "></i> Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProfileSettings;
