import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";
import { format } from "date-fns";
import Linkify from "linkifyjs/react";
import { Icon, Popup } from "semantic-ui-react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PulseLoader from "react-spinners/PulseLoader";
import "./BillingSettings.scss";
import checkedIcon from "../../images/checked.png";
import CardForm from "./Stripe/StripeForm";

const stripePromise = loadStripe(
  "pk_live_51H5IsRKIF9S36ViQKkry5tX2MiFqGoSFdTiDtv7inysyc46KytTCRd3ofNVFtiGbetuRybtsfeJ0jYTFLqOfNxNT00Y83MGAjf"
);

const featureList = [
  { id: 1, text: "Unlimited projects", highlight: true },
  { id: 2, text: "Unlimited submissions", highlight: true },
  { id: 3, text: "Unlimited users", highlight: false },
  { id: 4, text: "Whitelabel", highlight: true },
  { id: 5, text: "Custom domain", highlight: true },
];

const form = [
  {
    id: 1,
    name: "lifetime",
    title: "Lifetime Plan",
    price: 199,
    period: "",
    message: "Super Early Bird",
    checked: true,
  },
  {
    id: 2,
    name: "monthly",
    title: "Bill monthly",
    price: 29,
    period: "month",
    message: "",
    checked: false,
  },
  {
    id: 3,
    name: "yearly",
    title: "Bill yearly",
    price: 249,
    period: "year",
    message: "",
    checked: false,
  },
];

class BillingSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "lifetime",
      isLifetime:
        this.props.currentUser &&
        this.props.currentUser.plan === "lifetime" &&
        this.props.currentUser.paymentIntent.status === "succeeded"
          ? true
          : false,
      stripeSubscribed:
        this.props.currentUser &&
        this.props.currentUser.stripeSubscription &&
        this.props.currentUser.stripeSubscription.status === "active"
          ? true
          : false,
      isLoading: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.currentUser !== prevProps.currentUser) {
      const { currentUser } = this.props;
      this.setState({
        isLifetime:
          currentUser &&
          currentUser.plan === "lifetime" &&
          currentUser.paymentIntent.status === "succeeded"
            ? true
            : false,
        stripeSubscribed:
          currentUser &&
          currentUser.stripeSubscription &&
          currentUser.stripeSubscription.status === "active"
            ? true
            : false,
      });
    }
  };

  handleChange = (e) => {
    const { name } = e.target;
    this.setState({ select: name });
  };

  stripeCancelSubscription = () => {
    const { currentUser } = this.props;
    this.setState({ isLoading: true });
    try {
      var stripeCancelSubscription = firebase
        .functions()
        .httpsCallable("stripeCancelSubscription");
      stripeCancelSubscription({
        subscriptionId: currentUser.stripeSubscription.id,
      }).then(async (result) => {
        await firebase
          .database()
          .ref("users")
          .child(currentUser.uid)
          .child("stripeSubscription")
          .remove();
        toast.success("Your subscription is cancelled successfully.", {
          autoClose: 3000,
        });
        this.setState({ isLoading: false });
      });
    } catch (error) {
      // console.log("stripe cancel sub failed: ", error);
      toast.error("Oops, something went wrong. Please try it again.", {
        autoClose: 3000,
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { select, isLifetime, stripeSubscribed, isLoading } = this.state;
    const { currentUser } = this.props;

    var currentPlan = "";
    if (isLifetime) {
      currentPlan = "Lifetime";
    } else if (
      currentUser &&
      currentUser.stripeSubscription &&
      currentUser.stripeSubscription.plan.interval === "year"
    ) {
      currentPlan = "Pro Plan (billed annually)";
    } else if (
      currentUser &&
      currentUser.stripeSubscription &&
      currentUser.stripeSubscription.plan.interval === "month"
    ) {
      currentPlan = "Pro Plan (billed monthly)";
    } else {
      currentPlan = "Free Plan";
    }

    return !stripeSubscribed && !isLifetime ? (
      <div className="col-sm-9">
        <div className="border">
          <div className="text-left mb-3">
            <h3 className="mt-2" style={{ fontWeight: 600 }}>
              Upgrade to the Pro plan
            </h3>
            <p>
              We aim to serve better for our indie hackers community 💪 For the
              Pro plan, you can get extra control on using your own branding.
            </p>
          </div>
          <div className=" wrapper text-center">
            <div className="content ml-0">
              <div className="row pt-3 pt-lg-4">
                <div className="col-12">
                  <ul className="list mb-0">
                    {featureList.map((item) => (
                      <li
                        className="d-flex align-items-center mb-2 pb-2"
                        key={item.id}
                      >
                        <span className="icon">
                          <img src={checkedIcon} alt="" />
                        </span>
                        <div className="pl-2">
                          <p
                            className={
                              item.highlight
                                ? "feature-highlight mb-0"
                                : "font-size-md mb-0"
                            }
                          >
                            {item.text}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <form className="form">
                    {form.map((item) => (
                      <div className="form-group" key={item.id}>
                        <label
                          className={`card card-body d-flex flex-row justify-content-between align-items-center ${
                            select === item.name ? "active" : ""
                          }`}
                        >
                          <div className="col-12 col-md-6 d-flex align-items-center item-content">
                            <input
                              type="radio"
                              name={item.name}
                              className="item-input mr-3"
                              checked={select === item.name}
                              onChange={(e) => this.handleChange(e)}
                            />
                            <span className="item-checkmark"></span>
                            <div className="item-title">{item.title}</div>
                            {item.message && (
                              <div className="item-message">{item.message}</div>
                            )}
                          </div>

                          <div className="col-12 col-md-6 d-flex align-items-center">
                            <div className="item-price">{`$${item.price}`}</div>
                            <div className="item-period">
                              {item.name === "lifetime"
                                ? ""
                                : `/${item.period}`}
                            </div>
                          </div>
                        </label>
                      </div>
                    ))}
                  </form>
                  <div className="Checkout mt-4">
                    <Elements stripe={stripePromise}>
                      <CardForm
                        plan={select}
                        customer={this.props.currentUser}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : isLifetime ? (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2 mb-3" style={{ fontWeight: 600 }}>
            Billing
          </h3>
          <div className="billing-details">
            <div className="plan">
              <div className="label">Current plan:</div>
              <div className="value">{currentPlan}</div>
            </div>
            <div className="next-billing mb-3">
              <div className="label">
                Thank you for being a lifetime member🙏
              </div>
            </div>
            <img
              src="https://media1.giphy.com/media/g9582DNuQppxC/giphy.gif?cid=ecf05e47ibtkj6mhht2m6gpzy157hwtxvlxlzqlijwrfqh8i&rid=giphy.gifC"
              width="480"
              height="270"
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2 mb-3" style={{ fontWeight: 600 }}>
            Billing
          </h3>
          <div className="billing-details">
            <div className="plan">
              <div className="label">Current plan:</div>
              <div className="value">{currentPlan}</div>
            </div>
            <div className="next-billing">
              <div className="label">Next billing date:</div>
              <div className="value">
                <div className="timestamp">
                  {currentUser.stripeSubscription &&
                    format(
                      currentUser.stripeSubscription.current_period_end * 1000,
                      "PP"
                    )}
                </div>
              </div>
            </div>
            <div className="price">
              <div className="label">Price:</div>
              <div className="value">
                {currentPlan === "Pro Plan (billed annually)"
                  ? "$249/yr"
                  : "$29/mo"}
              </div>
            </div>
          </div>
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-home"
              role="tabpanel"
              aria-labelledby="nav-home-tab"
            >
              <div className="text-left border-less">
                <div className="col-12 pl-0 mt-4">
                  <button
                    className="btn btn-lg btn-primary"
                    onClick={this.stripeCancelSubscription}
                  >
                    {isLoading ? (
                      <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
                    ) : (
                      "Cancel subscription"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BillingSettings;
