/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment } from "react";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router";
import { ListGroup } from "react-bootstrap";
import PulseLoader from "react-spinners/PulseLoader";
import CompanySettings from "./CompanySettings";
import "./Settings.scss";

var unsubscribe;
var currentUserListener;

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: null,
      allCompanies: [],
      company: null,
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);

    const { subdomain } = this.props;
    if (subdomain) {
      const companyRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomain)
        .once("value");
      if (!companyRef.exists()) {
        this.props.history.push("/error");
        return;
      }
    }

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              currentUser: snapshot.val(),
              allCompanies:
                (snapshot.val().companies &&
                  Object.values(snapshot.val().companies)) ||
                [],
              isLoading: false,
            });
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.company !== prevProps.location.company) {
      this.setState({ company: this.props.location.state.company });
    }
  };

  componentWillUnmount = async () => {
    window.removeEventListener("scroll", this.scrollNavigation, true);

    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe && unsubscribe();
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 50) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  newFormToAddNewCompany = () => {
    this.setState({ company: null });
  };

  gotoUpgrade = () => {
    this.props.history.push({
      pathname: "/profile",
      state: { tabOption: "billing" },
    });
  };

  render() {
    const { currentUser, allCompanies, company, isLoading } = this.state;

    if (isLoading) {
      return (
        <Fragment>
          <main className="bg-light settings-container">
            <div
              className="text-center"
              style={{ marginTop: "150px", marginBottom: "150px" }}
            >
              <PulseLoader sizeUnit={"px"} size={8} color={"#5850ec"} />
            </div>
          </main>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <main className="bg-light settings-container">
          <div className="headerSpace container"></div>
          <div className="main">
            <div className="container mt-5 px-5">
              <h3 className="title"></h3>
              <div className="row mtop-minus">
                {/* Left column navigator */}
                <div className="col-sm-3 user_col">
                  <ListGroup className="mt-4">
                    {allCompanies.map((companyItor) => {
                      return (
                        <ListGroup.Item
                          active={company === companyItor ? true : false}
                          action
                          variant="light"
                          className="p-0 mb-2 shadow-none"
                          onClick={() =>
                            this.setState({
                              company: companyItor,
                            })
                          }
                          key={companyItor.subdomain}
                        >
                          <div className="company-item">
                            <img
                              className="company-logo mr-2"
                              src={companyItor.logo}
                              style={{
                                marginRight: 0,
                              }}
                            />
                            <div className="company-name">
                              {companyItor.name}
                            </div>
                          </div>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                  {(currentUser &&
                    currentUser.stripeSubscription &&
                    currentUser.stripeSubscription.status === "active") ||
                  (currentUser.plan === "lifetime" &&
                    currentUser.paymentIntent &&
                    currentUser.paymentIntent.status == "succeeded") ||
                  !currentUser.companies ||
                  (currentUser.companies &&
                    Object.keys(currentUser.companies) === 0) ? (
                    <div
                      onClick={this.newFormToAddNewCompany}
                      className="btn btn-primary btn-new-company mt-3"
                    >
                      + New project
                    </div>
                  ) : (
                    <div
                      onClick={this.gotoUpgrade}
                      className="btn btn-secondary btn-upgrade-cta mt-3"
                    >
                      🔒 Upgrade to add
                    </div>
                  )}
                </div>
                {/* End left column navigator */}

                <CompanySettings currentUser={currentUser} company={company} />
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Settings);
