import React, { Fragment } from "react";
import { withRouter } from "react-router";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SectionTitle from "../../components/Shared/section-title";
import PricingTable from "../../components/Shared/pricing-box2";
import "./Pricing.css";

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings1: [
        {
          id: 1,
          title: "Free Forever",
          price: 0,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
        {
          id: 2,
          isActive: true,
          title: "Lifetime",
          plan: "lifetime",
          price: 199,
          duration: "",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Lifetime access", highlight: true },
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
        {
          id: 3,
          title: "Pro Monthly",
          plan: "month",
          price: 29,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
      ],
      pricings2: [
        {
          id: 1,
          title: "Free Forever",
          price: 0,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
        {
          id: 2,
          title: "Lifetime",
          plan: "lifetime",
          price: 199,
          duration: "",
          isActive: true,
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Lifetime access", highlight: true },
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
        {
          id: 3,
          title: "Pro Yearly",
          price: 249,
          duration: "year",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
      ],
      activeTab: "2",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount = () => {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 50) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <Fragment>
        <section className="pricing-container">
          <Container className="mt-100" id="pricing">
            <SectionTitle
              title="Simple Pricing"
              desc="We aim to serve better for our indie hackers community 💪. We offer free plan for all with all core features. For the Pro plan, you can get extra control on using your own branding."
            />

            <Row className="align-items-center">
              <Col xs="12" className="mt-2 ">
                <div className="text-center">
                  <Nav
                    pills
                    className="rounded-pill justify-content-center d-inline-block border py-1 px-2"
                  >
                    <NavItem className="d-inline-block mr-1">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === "1" },
                          "px-3",
                          "rounded-pill",
                          "monthly"
                        )}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        Monthly
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-inline-block">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === "2" },
                          "px-3",
                          "rounded-pill",
                          "yearly"
                        )}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Yearly 🤩
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={this.state.activeTab} className="mt-5">
                  <TabPane tabId="1">
                    <Row>
                      {/* pricing */}
                      <PricingTable pricings={this.state.pricings1} />
                    </Row>
                  </TabPane>

                  <TabPane tabId="2">
                    <Row>
                      {/* pricing */}
                      <PricingTable pricings={this.state.pricings2} />
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    );
  }
}

export default withRouter(Pricing);
