import { SET_USER_VALUE } from "../constant";

const initialState = {
  currentUser: null,
  showSignInModal: false,
  showLoginForm: false,
  showSignOutModal: false,
};

export default function formReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_VALUE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
