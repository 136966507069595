import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { toast } from "react-toastify";
import { Form, InputGroup, Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import addIcon from "./assets/add.svg";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

class CompanySettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      website: "",
      subdomain: "",
      customDomain: "",
      whiteLabel: false,
      logoImg: "",
      updatedLogo: false,
      loading: false,
      isLifetime:
        this.props.currentUser &&
        this.props.currentUser.plan === "lifetime" &&
        this.props.currentUser.paymentIntent.status === "succeeded"
          ? true
          : false,
      stripeSubscribed:
        this.props.currentUser &&
        this.props.currentUser.stripeSubscription &&
        this.props.currentUser.stripeSubscription.status === "active"
          ? true
          : false,
      widgetCode: "",
      adminsList: [
        {
          email: "",
        },
      ],
      jsCopied: false,
    };
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentDidMount() {
    const { company } = this.props;
    this.setState({
      name: company ? company.name : "",
      website: company ? company.website : "",
      subdomain: company ? company.subdomain : "",
      customDomain: company ? company.customDomain : "",
      whiteLabel: company && company.whiteLabel ? company.whiteLabel : false,
      logoImg: company ? company.logo : "",
      widgetCode:
        '<script>(function(d,script){script=d.createElement("script");script.type="text/javascript";script.async=true;script.onload=function(){BacklogsBadge.init({subdomain:"' +
        (company ? company.subdomain : "") +
        '",position:"right",customDomain:"' +
        (company ? company.customDomain : "") +
        '"})};script.src="https://backlogs.co/backlogsBadge.js";d.getElementsByTagName("head")[0].appendChild(script)})(document);</script>',
      adminsList:
        company && company.adminsList ? company.adminsList : [{ email: "" }],
    });
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.company !== prevProps.company) {
      const { company } = this.props;
      this.setState({
        name: company ? company.name : "",
        website: company ? company.website : "",
        subdomain: company ? company.subdomain : "",
        customDomain: company ? company.customDomain : "",
        whiteLabel: company && company.whiteLabel ? company.whiteLabel : false,
        logoImg: company ? company.logo : "",
        widgetCode:
          '<script>(function(d,script){script=d.createElement("script");script.type="text/javascript";script.async=true;script.onload=function(){BacklogsBadge.init({subdomain:"' +
          (company ? company.subdomain : "") +
          '",position:"right",customDomain:"' +
          (company ? company.customDomain : "") +
          '"})};script.src="https://backlogs.co/backlogsBadge.js";d.getElementsByTagName("head")[0].appendChild(script)})(document);</script>',
        jsCopied: false,
        adminsList:
          company && company.adminsList ? company.adminsList : [{ email: "" }],
      });
    }
    if (this.props.currentUser !== prevProps.currentUser) {
      const { currentUser } = this.props;
      this.setState({
        isLifetime:
          currentUser &&
          currentUser.plan === "lifetime" &&
          currentUser.paymentIntent.status === "succeeded"
            ? true
            : false,
        stripeSubscribed:
          currentUser &&
          currentUser.stripeSubscription &&
          currentUser.stripeSubscription.status === "active"
            ? true
            : false,
      });
    }
  };

  onChangeText = (event) => {
    const value =
      event.target.id === "whiteLabel"
        ? event.target.checked
        : event.target.value;
    this.setState({ [event.target.id]: value });
  };

  onUpdateSettings = async () => {
    const {
      name,
      website,
      subdomain,
      customDomain,
      whiteLabel,
      logoImg,
      updatedLogo,
      adminsList,
    } = this.state;
    const { currentUser, company } = this.props;

    if (name === "" || website === "" || subdomain === "" || logoImg === "") {
      toast.error("Oops, all fields are required.", {
        autoClose: 4000,
      });
      return;
    }

    // update custom domain
    if (
      (!company && customDomain !== "") ||
      (company && customDomain !== company.customDomain)
    ) {
      const newURL = customDomain.replace(/\./g, "-");
      // check if custom domain is taken
      const customDomainRef = await firebase
        .database()
        .ref("custom-domains")
        .child(newURL)
        .once("value");
      if (customDomainRef.exists()) {
        toast.error(
          "Oops, it seems this custom domain is taken. Please make sure you own it.",
          {
            autoClose: 4000,
          }
        );
        return;
      }
    }

    const subdomainLowerCase = subdomain.toLowerCase();
    //check if subdomain is changed. then check if subdomain is taken or available
    if (!company) {
      //check is new subdomain is valid in regex
      var subdomainRegex = /^([a-zA-Z0-9\-]{2,})+$/;
      if (subdomainLowerCase.match(subdomainRegex) === null) {
        //didn't pass the subdomain validator, toast error and return
        if (subdomainLowerCase.length < 2) {
          toast.error("subdomain must be at least 2 characters long", {
            autoClose: 4000,
          });
          return;
        }
        toast.error(
          'Your subdomain can only contain letters, numbers and "-"',
          {
            autoClose: 4000,
          }
        );
        return;
      }

      const subdomainRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomainLowerCase)
        .once("value");

      if (subdomainRef.exists() === false) {
        await firebase //add new subdomain
          .database()
          .ref("subdomains")
          .child(subdomainLowerCase)
          .set({
            name: name,
            website: website,
            subdomain: subdomain,
            customDomain: customDomain,
            whiteLabel: whiteLabel,
            admin: {
              name: currentUser.displayName,
              email: currentUser.email,
              uid: currentUser.uid,
            },
          });
      } else {
        //subdomain is taken
        toast.error("Sorry, this subdomain is taken.", {
          autoClose: 4000,
        });
        return;
      }
    }

    var fileURL = "";
    if (logoImg !== "" && updatedLogo) {
      var storageRef = await firebase.storage().ref(currentUser.uid);
      var logoName = "logo" + Date();
      var fileRef = storageRef.child("products").child(name).child(logoName);
      var type = logoImg.split(";")[0].split(":")[1];

      var uploadedFile = await fileRef.putString(
        logoImg.split(",")[1],
        "base64",
        {
          contentType: type,
        }
      );
      fileURL = await uploadedFile.ref.getDownloadURL();
    } else {
      fileURL = logoImg;
    }

    await firebase //add new subdomain
      .database()
      .ref("subdomains")
      .child(subdomainLowerCase)
      .update({
        logo: fileURL,
        adminsList: adminsList,
      });

    await firebase
      .database()
      .ref("users")
      .child(currentUser.uid)
      .child("companies")
      .child(subdomain)
      .update({
        name: name,
        website: website,
        subdomain: subdomain,
        customDomain: customDomain,
        whiteLabel: whiteLabel,
        logo: fileURL,
        adminsList: adminsList,
      });

    if (!company) {
      //create some demo cards for the newly added project
      const pendingRequestId = "thanks-for-signing-up";
      var pendingRequest = {
        id: pendingRequestId,
        submitter: {
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        },
        name: "Thanks for signing up 🙏",
        details:
          "All feedback will be placed in the **Pending** column initially. You can move it to **Planned** or **Ongoing** column accordingly.\n\nIf you need any help, you can directly reach out to me via damon@backlogs.co. Or you can submit a feedback in our [feedback page](https://feedback.backlogs.co/).\n\nWe also have some instruction articles here: [help.backlogs.co](http://help.backlogs.co/)",
        photo: "",
        product: subdomain,
        type: "BUG",
        color: `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`,
        stats: { replyCount: 0, viewCount: 1 },
        status: "PENDING",
        createdAt: Date.now(),
        priority: Date.now(),
        voteCount: 1,
      };

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(pendingRequestId)
        .set(pendingRequest);

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(pendingRequestId)
        .child("votedBy")
        .child(currentUser.uid)
        .set({
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        });

      await firebase //add to changelogs
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(pendingRequestId)
        .child("changeLogs")
        .push({
          status: "PENDING",
          comment: "",
          photo: "",
          notify: false,
          createdAt: Date.now(),
        });

      const plannedRequestId = "start-building-your-product-in-public";
      var plannedRequest = {
        id: plannedRequestId,
        submitter: {
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        },
        name: "Start building your product in public 💪",
        details:
          "Actively engage with your users. Show your commitment in the roadmap board and build your product in public!",
        photo: "",
        product: subdomain,
        type: "FEATURE",
        color: `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`,
        stats: { replyCount: 0, viewCount: 1 },
        status: "PLANNED",
        createdAt: Date.now(),
        priority: Date.now(),
        voteCount: 1,
      };

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(plannedRequestId)
        .set(plannedRequest);

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(plannedRequestId)
        .child("votedBy")
        .child(currentUser.uid)
        .set({
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        });

      await firebase //add to changelogs
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(plannedRequestId)
        .child("changeLogs")
        .push({
          status: "PLANNED",
          comment: "",
          photo: "",
          notify: false,
          createdAt: Date.now(),
        });

      const ongoingRequestId = "let-me-know-your-feedback";
      var ongoingRequest = {
        id: ongoingRequestId,
        submitter: {
          uid: currentUser.uid,
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        },
        name: "Let me know your feedback as well 🤗",
        details:
          "Backlogs is built in public as well. If you have any feedback, feel free to let me know. Once again, I am so happy to have you and your product on board!",
        photo: "",
        product: subdomain,
        type: "OTHER",
        color: `hsl(${Math.floor(Math.random() * 360)}, 100%, 93%)`,
        stats: { replyCount: 0, viewCount: 1 },
        status: "ONGOING",
        createdAt: Date.now(),
        priority: Date.now(),
        voteCount: 1,
      };

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(ongoingRequestId)
        .set(ongoingRequest);

      await firebase
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(ongoingRequestId)
        .child("votedBy")
        .child(currentUser.uid)
        .set({
          avatar: currentUser.avatar,
          name: currentUser.displayName,
          email: currentUser.email || "",
        });

      await firebase //add to changelogs
        .database()
        .ref("company-posts")
        .child(subdomain)
        .child("active")
        .child(ongoingRequestId)
        .child("changeLogs")
        .push({
          status: "ONGOING",
          comment: "",
          photo: "",
          notify: false,
          createdAt: Date.now(),
        });
    }

    // update custom domain
    if (
      (!company && customDomain !== "") ||
      (company && customDomain !== company.customDomain)
    ) {
      const newURL = customDomain.replace(/\./g, "-");
      if (company && company.customDomain && company.customDomain !== "") {
        // delete old custom domain
        const oldURL = company.customDomain.replace(/\./g, "-");
        await firebase.database().ref("custom-domains").child(oldURL).remove();
      }

      // add new custom domain
      await firebase
        .database()
        .ref("custom-domains")
        .child(newURL)
        .set({
          subdomain: subdomainLowerCase,
          customDomain: customDomain,
          admin: {
            email: currentUser.email,
            name: currentUser.displayName,
            uid: currentUser.uid,
          },
          adminsList: adminsList,
        });
    }
    if (company) {
      // update an existing project
      toast.success("Your project is saved successfully.", {
        autoClose: 3000,
      });

      var newCompany = company;
      newCompany.customDomain = customDomain;

      this.props.history.push({
        pathname: "/settings",
        state: { company: newCompany },
      });
    } else {
      // add a new project
      toast.success(
        "Your project " + this.state.name + " is added successfully.",
        {
          autoClose: 3000,
        }
      );
      this.setState({
        name: "",
        website: "",
        subdomain: "",
        customDomain: "",
        whiteLabel: false,
        logoImg: "",
        updatedLogo: false,
      });
    }
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (logoImg) =>
        this.setState({
          logoImg,
          updatedLogo: true,
          loading: false,
        })
      );
    }
  };

  onUpgrade = () => {
    this.props.history.push({
      pathname: "/profile",
      state: { tabOption: "billing" },
    });
  };

  handleAdminRemove = (e, index) => {
    e.preventDefault();
    const { adminsList } = this.state;
    const list = [...adminsList];
    list.splice(index, 1);
    this.setState({ adminsList: list });
  };

  handleAdminAdd = (e) => {
    e.preventDefault();
    const { adminsList } = this.state;
    if (adminsList.length === 5) {
      return;
    }
    this.setState({
      adminsList: [
        ...adminsList,
        {
          email: "",
        },
      ],
    });
  };

  handleAdminChange = (e, index) => {
    e.preventDefault();
    const { adminsList } = this.state;
    const { value } = e.target;
    const list = [...adminsList];
    list[index]["email"] = value;
    console.log("adminsList list", list);
    this.setState({ adminsList: list });
  };

  render() {
    const {
      name,
      website,
      subdomain,
      customDomain,
      whiteLabel,
      logoImg,
      isLifetime,
      stripeSubscribed,
      widgetCode,
      jsCopied,
      adminsList,
    } = this.state;
    const { company, currentUser } = this.props;

    const uploadButton = (
      <div>
        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2" style={{ fontWeight: 600 }}>
            {company ? company.name : "Add New Project"}
          </h3>

          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-profile"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <Form>
                <Form.Group controlId="name">
                  <Form.Label>
                    Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Project name"
                    value={name}
                    onChange={this.onChangeText}
                  />
                </Form.Group>

                <Form.Group controlId="website">
                  <Form.Label>
                    Website <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="myproject.com"
                    value={website}
                    onChange={this.onChangeText}
                  />
                </Form.Group>
                <Form.Group controlId="subdomain">
                  <Form.Label>
                    Choose a subdomain{" "}
                    <span
                      style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}
                    >
                      (only set once)
                    </span>{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="subdomain"
                      aria-describedby="inputGroupPrepend"
                      value={subdomain}
                      onChange={this.onChangeText}
                      disabled={company ? true : false}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text id="inputGroupPrepend">
                        .backlogs.co
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="customDomain">
                  <Form.Label>
                    Custom domain{" "}
                    {!stripeSubscribed && !isLifetime && (
                      <span
                        className="upgrade-cta-btn"
                        onClick={() => this.onUpgrade()}
                      >
                        <i className="fas fa-rocket"></i> Upgrade
                      </span>
                    )}
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        https://
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      required
                      disabled={!stripeSubscribed && !isLifetime}
                      type="text"
                      placeholder={
                        stripeSubscribed || isLifetime
                          ? "feedback.acme.com"
                          : "🔒 Only for Paid Plan"
                      }
                      value={customDomain}
                      onChange={this.onChangeText}
                    />
                  </InputGroup>
                  {(stripeSubscribed || isLifetime) && (
                    <Form.Text id="passwordHelpBlock" muted>
                      <span className="custom-domain-tips">
                        <i className="fas fa-exclamation-circle"></i> Please
                        follow{" "}
                        <a
                          href="https://help.backlogs.co/advanced/set-up-custom-domain"
                          target="_blank"
                          style={{ fontWeight: 700 }}
                        >
                          our instructions
                        </a>{" "}
                        to complete the custom domain setup.
                      </span>
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group controlId="whiteLabel">
                  <Form.Check
                    type="switch"
                    id="whiteLabel"
                    disabled={!stripeSubscribed && !isLifetime}
                    label="Whitelabel?"
                    checked={whiteLabel}
                    onChange={this.onChangeText}
                  />

                  <Form.Text id="passwordHelpBlock" muted>
                    <span className="custom-domain-tips">
                      <i className="far fa-lightbulb"></i> Hide all Backlogs
                      branding from your page.{" "}
                      {!stripeSubscribed && !isLifetime && (
                        <span
                          className="upgrade-cta-btn"
                          onClick={() => this.onUpgrade()}
                        >
                          <i className="fas fa-rocket"></i> Upgrade
                        </span>
                      )}
                    </span>
                  </Form.Text>
                </Form.Group>
                <Form.Group controlId="logoImg">
                  <Form.Label>
                    Logo <span className="text-danger">*</span>
                  </Form.Label>
                  <div className="clearfix">
                    <Upload
                      accept="image/*"
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      beforeUpload={beforeUpload}
                      onChange={this.handleChange}
                    >
                      {logoImg ? (
                        <img
                          src={logoImg}
                          alt="avatar"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                  </div>
                </Form.Group>
                <Container>
                  <Row>
                    <Form.Label>
                      Assign new admin{" "}
                      {!stripeSubscribed && !isLifetime && (
                        <span
                          className="upgrade-cta-btn"
                          onClick={() => this.onUpgrade()}
                        >
                          <i className="fas fa-rocket"></i> Upgrade
                        </span>
                      )}
                    </Form.Label>
                  </Row>

                  <Row>
                    <Col>
                      {adminsList.map((item, index) => {
                        return (
                          <Row key={index}>
                            <Form.Group
                              className={
                                index > 0
                                  ? "col-12 col-md-6 px-0"
                                  : "col-12 col-md-6 px-0 pt-0"
                              }
                              style={{
                                display: "inline-flex",
                                position: "relative",
                              }}
                            >
                              <Form.Control
                                required
                                type="text"
                                placeholder="new-admin@email.address"
                                value={item.email}
                                onChange={(e) =>
                                  this.handleAdminChange(e, index)
                                }
                                disabled={
                                  !stripeSubscribed && !isLifetime
                                    ? true
                                    : false
                                }
                              />
                              {adminsList.length !== 1 && (
                                <button
                                  className="btn btn-link btn-icon btn-remove"
                                  onClick={(e) =>
                                    this.handleAdminRemove(e, index)
                                  }
                                >
                                  <span className="btn-span">
                                    <i className="fas fa-trash"></i>
                                  </span>
                                </button>
                              )}
                            </Form.Group>
                          </Row>
                        );
                      })}
                    </Col>
                  </Row>
                </Container>

                {(stripeSubscribed || isLifetime) && (
                  <Container>
                    <Row>
                      <button
                        className="btn btn-link btn-icon"
                        type="text"
                        onClick={this.handleAdminAdd}
                      >
                        <i className="fas fa-user-plus mr-2"></i>
                        Add another admin
                      </button>
                    </Row>
                  </Container>
                )}
              </Form>
              {company && (
                <div>
                  <Form.Label>
                    Embeddable JS Widget{" "}
                    <span
                      style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.4)" }}
                    >
                      (change the position to "right" or "left" to fit your
                      need)
                    </span>
                    <CopyToClipboard
                      text={widgetCode}
                      onCopy={() => this.setState({ jsCopied: true })}
                    >
                      <i
                        className="far fa-copy ml-2"
                        style={{ cursor: "pointer" }}
                      ></i>
                    </CopyToClipboard>
                  </Form.Label>

                  <SyntaxHighlighter language="javascript" style={atomDark}>
                    {widgetCode}
                  </SyntaxHighlighter>

                  {jsCopied ? (
                    <span className="text-muted">Copied!</span>
                  ) : null}
                </div>
              )}
              <div className="text-right border-less">
                <div className="col-12 mt-3">
                  {company || // update existing company
                  stripeSubscribed ||
                  isLifetime ||
                  !currentUser.companies ||
                  (currentUser.companies &&
                    Object.keys(currentUser.companies) === 0) ? (
                    <button
                      className="btn btn-lg btn-primary"
                      onClick={this.onUpdateSettings}
                    >
                      <i className="fas fa-save "></i> Save
                    </button>
                  ) : (
                    <button className="btn btn-lg btn-primary" disabled={true}>
                      Upgrade to add
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CompanySettings);
