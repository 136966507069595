/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment } from "react";

import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/storage";
import { withRouter } from "react-router";
import PulseLoader from "react-spinners/PulseLoader";
import { ListGroup } from "react-bootstrap";
import ProfileSettings from "./ProfileSettings";
import PlanSettings from "./PlanSettings";
import BillingSettings from "./BillingSettings";
import "./Profile.css";

import { StripeProvider } from "react-stripe-elements";

var unsubscribe;
var currentUserListener;
class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkProfile: true,
      currentUser: null,
      tabOption:
        (this.props.location.state && this.props.location.state.tabOption) ||
        "profile",
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);

    const { subdomain } = this.props;
    if (subdomain) {
      const companyRef = await firebase
        .database()
        .ref("subdomains")
        .child(subdomain)
        .once("value");
      if (!companyRef.exists()) {
        this.props.history.push("/error");
        return;
      }
    }

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({ currentUser: snapshot.val(), isLoading: false });
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentWillUnmount = async () => {
    window.removeEventListener("scroll", this.scrollNavigation, true);

    this.state.currentUser &&
      (await firebase
        .database()
        .ref("users")
        .child(this.state.currentUser.uid)
        .off("value", currentUserListener));
    unsubscribe && unsubscribe();
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 50) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  onUpgrade = () => {
    this.setState({ tabOption: "billing" });
  };

  render() {
    const { currentUser, tabOption, isLoading } = this.state;

    if (isLoading) {
      return (
        <Fragment>
          <main className="bg-light profile-container">
            <div
              className="text-center"
              style={{ marginTop: "150px", marginBottom: "150px" }}
            >
              <PulseLoader sizeUnit={"px"} size={8} color={"#5850ec"} />
            </div>
          </main>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <main className="bg-light profile-container">
          <div className="">
            <div className="container mt-5 px-5">
              <h3 className="title"></h3>
              <div className="row">
                {/* Left column navigator */}
                <div className="col-sm-3 user_col">
                  <ListGroup className="mt-4">
                    <ListGroup.Item
                      active={tabOption === "profile" ? true : false}
                      action
                      variant="light"
                      className="p-0 mb-2 shadow-none"
                      onClick={() =>
                        this.setState({
                          tabOption: "profile",
                        })
                      }
                    >
                      <div className="settings-tabs">
                        <div className="settings-tab-name">Profile</div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      active={tabOption === "plans" ? true : false}
                      action
                      variant="light"
                      className="p-0 mb-2 shadow-none"
                      onClick={() =>
                        this.setState({
                          tabOption: "plans",
                        })
                      }
                    >
                      <div className="settings-tabs">
                        <div className="settings-tab-name">Plans</div>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item
                      active={tabOption === "billing" ? true : false}
                      action
                      variant="light"
                      className="p-0 mb-2 shadow-none"
                      onClick={() =>
                        this.setState({
                          tabOption: "billing",
                        })
                      }
                    >
                      <div className="settings-tabs">
                        <div className="settings-tab-name">Billing</div>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </div>
                {/* End left column navigator */}

                {tabOption === "profile" && (
                  <ProfileSettings currentUser={currentUser} tab={tabOption} />
                )}
                {tabOption === "plans" && (
                  <PlanSettings
                    currentUser={currentUser}
                    tab={tabOption}
                    onUpgrade={this.onUpgrade}
                  />
                )}
                {tabOption === "billing" && (
                  <StripeProvider apiKey="pk_live_51H5IsRKIF9S36ViQKkry5tX2MiFqGoSFdTiDtv7inysyc46KytTCRd3ofNVFtiGbetuRybtsfeJ0jYTFLqOfNxNT00Y83MGAjf">
                    <BillingSettings
                      currentUser={currentUser}
                      tab={tabOption}
                    />
                  </StripeProvider>
                )}
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    );
  }
}

export default withRouter(Profile);
