// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

//Import Containers
import ReviewsSlider from "../../components/Shared/reviews-slider";

//Import Images
import img1 from "../../images/client/01.jpg";
import img2 from "../../images/client/02.jpg";
import img3 from "../../images/client/03.jpg";
import img4 from "../../images/client/04.jpg";
import img5 from "../../images/client/05.jpg";
import img6 from "../../images/client/06.jpg";

class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviews: [
        {
          id: 1,
          img: img6,
          name: "Illen Webb",
          post: "Founder",
          desc:
            "Managing different request from our users used to be nerve cracking. But with Backlogs, I can manage all of them into one platform. It saved me tons of time. And I will give back those time to build a better product",
          rating: 4,
        },
        {
          id: 2,
          img: img1,
          name: "Thomas Israel",
          post: "Founder",
          desc:
            "Backlogs brings more engagement with our users. Having them to contribute to our product is so amazing!",
          rating: 5,
        },
        {
          id: 3,
          img: img2,
          name: "Barbara McIntosh",
          post: "Founder",
          desc:
            "With Backlogs, all requests are managed in one place. I don't need to dig into my emails to find what my customers previously asked for.",
          rating: 4,
        },
        {
          id: 4,
          img: img3,
          name: "Carl Oliver",
          post: "Founder",
          desc:
            "The roadmap feature really helps me track all requests in a more organized way. I think I can say goodbye to Trello boards.",
          rating: 3,
        },
        {
          id: 5,
          img: img4,
          name: "Christa Smith",
          post: "Founder",
          desc:
            "I had annual plan, it only costs me $19/mo. I litterally get everything unlimited, which is really a great deal for bootstrapped founders like me.",
          rating: 5,
        },
        {
          id: 6,
          img: img5,
          name: "Dean Tolle",
          post: "Founder",
          desc:
            "Backlogs allows my users to be part of our product journey. They can vote on what feature they like. It helps us do the right things. We can avoid shipping useless stuff.",
          rating: 5,
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="justify-content-center">
            <Col xs="12" className="text-center">
              <div className="section-title mb-60">
                <h4 className="title mb-4">Loved By Indie Hackers</h4>
              </div>
            </Col>
          </Row>

          {/* clients slider */}
          <ReviewsSlider reviews={this.state.reviews} />
        </Container>
      </React.Fragment>
    );
  }
}

export default Client;
