import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";
import { Container, Row, Col } from "reactstrap";

// Import images
import homeShape from "../../images/saas/home-shape.png";
import homeGif from "../../images/saas/home.gif";

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onGoToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    const { currentUser } = this.props.user;
    return (
      <React.Fragment>
        <div class="relative overflow-hidden">
          <div class="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"></div>

          <div class="relative pt-6">
            <main class="mt-32 mx-auto max-w-screen-xl px-4 sm:px-6 xl:mt-40">
              <div class="text-center">
                <h2 class="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Make customers{" "}
                  <span class="text-indigo-600">part of your journey</span>
                </h2>
                <p class="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  A single place to manage customers' feedback. Show your
                  commitment in a public roadmap. And engage everyone with a
                  product changelog.
                </p>
                <div class="mt-2 mb-12 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                  <div class="rounded-md shadow">
                    {currentUser ? (
                      <button
                        onClick={this.onGoToDashboard}
                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        My Dashboard
                      </button>
                    ) : (
                      <button
                        onClick={this.onShowAuthModal}
                        class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        Get started for free
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </main>
            <div class="justify-content-center">
              <div class="max-w-screen-xl mx-auto px-4 sm:px-6">
                <img
                  class="rounded-lg w-full lg:px-16"
                  src={homeGif}
                  alt="App screenshot"
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Section);
