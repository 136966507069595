import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";
import "./pricing-box.css";

class PricingBox2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onShowAuthModal = () => {
    this.props.setUserValue({ showSignInModal: true });
  };

  onGoToDashboard = () => {
    this.props.history.push("/dashboard");
  };

  render() {
    const { pricings, user } = this.props;
    const { currentUser } = user;
    return (
      <React.Fragment>
        <div class="bg-white">
          <div class="mt-16 bg-white lg:mt-20">
            <div class="relative z-0">
              <div class="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="relative lg:grid lg:grid-cols-7">
                  <div class="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                    <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-l-lg">
                      <div class="flex-1 flex flex-col">
                        <div class="bg-white px-6 py-10">
                          <div>
                            <h3
                              className="text-center text-2xl leading-8 font-medium text-gray-900"
                              id="tier-hobby"
                            >
                              {pricings[0].title}
                            </h3>
                            <div class="mt-4 flex items-center justify-center">
                              <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                                <span class="mt-2 mr-2 text-4xl font-medium">
                                  $
                                </span>
                                <span class="font-extrabold">
                                  {pricings[0].price}
                                </span>
                              </span>
                              <span class="text-xl leading-7 font-medium text-gray-500">
                                /month
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                          <ul>
                            <li class="flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                One project
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Unlimited users
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                50 submissions per month
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Unlimited changelog entries
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                All core features
                              </p>
                            </li>
                          </ul>
                          <div class="mt-8">
                            <div class="rounded-lg shadow-md">
                              {currentUser ? (
                                <button
                                  onClick={this.onGoToDashboard}
                                  class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  style={{ fontSize: "18px", fontWeight: 700 }}
                                >
                                  My Dashboard
                                </button>
                              ) : (
                                <button
                                  onClick={this.onShowAuthModal}
                                  class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  style={{ fontSize: "18px", fontWeight: 700 }}
                                >
                                  Get Started
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                    <div class="relative z-10 rounded-lg shadow-xl">
                      <div class="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"></div>
                      <div class="absolute inset-x-0 top-0 transform translate-y-px">
                        <div class="flex justify-center transform -translate-y-1/2">
                          <span class="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm leading-5 font-semibold tracking-wider uppercase text-white">
                            Super Early Bird
                          </span>
                        </div>
                      </div>
                      <div class="bg-white rounded-t-lg px-6 pt-12 pb-10">
                        <div>
                          <h3
                            class="text-center text-3xl leading-9 font-semibold text-gray-900 sm:-mx-6"
                            id="tier-growth"
                          >
                            {pricings[1].title}
                          </h3>
                          <div class="mt-4 flex items-center justify-center">
                            <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900 sm:text-6xl">
                              <span class="mt-2 mr-2 text-4xl font-medium">
                                $
                              </span>
                              <span class="font-extrabold">
                                {pricings[1].price}
                              </span>
                            </span>
                            <span class="text-2xl leading-8 font-medium text-gray-500">
                              once
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-10">
                        <ul>
                          <li class="flex items-start">
                            <div class="flex-shrink-0">
                              <svg
                                class="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                              One time investment, lifetime access
                            </p>
                          </li>
                          <li class="mt-4 flex items-start">
                            <div class="flex-shrink-0">
                              <svg
                                class="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                              Unlimited projects
                            </p>
                          </li>
                          <li class="mt-4 flex items-start">
                            <div class="flex-shrink-0">
                              <svg
                                class="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                              Whitelabel
                            </p>
                          </li>
                          <li class="mt-4 flex items-start">
                            <div class="flex-shrink-0">
                              <svg
                                class="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                              Custom Domain
                            </p>
                          </li>
                          <li class="mt-4 flex items-start">
                            <div class="flex-shrink-0">
                              <svg
                                class="h-6 w-6 text-green-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M5 13l4 4L19 7"
                                />
                              </svg>
                            </div>
                            <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                              Plus everything free plan covers
                            </p>
                          </li>
                        </ul>
                        <div class="mt-10">
                          <div class="rounded-lg shadow-md">
                            {currentUser ? (
                              <button
                                onClick={this.onGoToDashboard}
                                class="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                                style={{ fontSize: "18px", fontWeight: 700 }}
                              >
                                My Dashboard
                              </button>
                            ) : (
                              <button
                                onClick={this.onShowAuthModal}
                                class="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                                style={{ fontSize: "18px", fontWeight: 700 }}
                              >
                                Get Started
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                    <div class="h-full flex flex-col rounded-lg shadow-lg overflow-hidden lg:rounded-none lg:rounded-r-lg">
                      <div class="flex-1 flex flex-col">
                        <div class="bg-white px-6 py-10">
                          <div>
                            <h3
                              class="text-center text-2xl leading-8 font-medium text-gray-900"
                              id="tier-scale"
                            >
                              {pricings[2].title}
                            </h3>
                            <div class="mt-4 flex items-center justify-center">
                              <span class="px-3 flex items-start text-6xl leading-none tracking-tight text-gray-900">
                                <span class="mt-2 mr-2 text-4xl font-medium">
                                  $
                                </span>
                                <span class="font-extrabold">
                                  {pricings[2].price}
                                </span>
                              </span>
                              <span class="text-xl leading-7 font-medium text-gray-500">
                                {pricings[2].plan === "month"
                                  ? "/month"
                                  : "/year"}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                          <ul>
                            <li class="flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Unlimited projects
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Whitelabel
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Custom Domain
                              </p>
                            </li>
                            <li class="mt-4 flex items-start">
                              <div class="flex-shrink-0">
                                <svg
                                  class="h-6 w-6 text-green-500"
                                  stroke="currentColor"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                              </div>
                              <p class="ml-3 text-base leading-6 font-medium text-gray-500">
                                Plus everything free plan covers
                              </p>
                            </li>
                          </ul>
                          <div class="mt-8">
                            <div class="rounded-lg shadow-md">
                              {currentUser ? (
                                <button
                                  onClick={this.onGoToDashboard}
                                  class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  style={{ fontSize: "18px", fontWeight: 700 }}
                                >
                                  My Dashboard
                                </button>
                              ) : (
                                <button
                                  onClick={this.onShowAuthModal}
                                  class="block w-full text-center rounded-lg border border-transparent bg-white px-6 py-3 text-base leading-6 font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:shadow-outline transition ease-in-out duration-150"
                                  style={{ fontSize: "18px", fontWeight: 700 }}
                                >
                                  Get Started
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PricingBox2);
