import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/functions";
import { withRouter } from "react-router";
import checkedIcon from "../../images/checked.png";

const featureList = [
  { id: 1, text: "Unlimited projects", highlight: true },
  { id: 2, text: "Unlimited submissions", highlight: true },
  { id: 3, text: "Unlimited users", highlight: false },
  { id: 4, text: "Whitelabel", highlight: true },
  { id: 5, text: "Custom domain", highlight: true },
];

class PlanSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLifetime:
        this.props.currentUser &&
        this.props.currentUser.plan === "lifetime" &&
        this.props.currentUser.paymentIntent.status === "succeeded"
          ? true
          : false,
      stripeSubscribed:
        this.props.currentUser &&
        this.props.currentUser.stripeSubscription &&
        this.props.currentUser.stripeSubscription.status === "active"
          ? true
          : false,
      isLoading: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.currentUser !== prevProps.currentUser) {
      const { currentUser } = this.props;
      this.setState({
        isLifetime:
          currentUser &&
          currentUser.plan === "lifetime" &&
          currentUser.paymentIntent.status === "succeeded"
            ? true
            : false,
        stripeSubscribed:
          currentUser &&
          currentUser.stripeSubscription &&
          currentUser.stripeSubscription.status === "active"
            ? true
            : false,
      });
    }
  };

  render() {
    const { isLifetime, stripeSubscribed, isLoading } = this.state;
    const { currentUser } = this.props;

    var currentPlan = "";
    if (isLifetime) {
      currentPlan = "Lifetime";
    } else if (
      currentUser &&
      currentUser.stripeSubscription &&
      currentUser.stripeSubscription.plan.interval === "year"
    ) {
      currentPlan = "Pro Plan (billed annually)";
    } else if (
      currentUser &&
      currentUser.stripeSubscription &&
      currentUser.stripeSubscription.plan.interval === "month"
    ) {
      currentPlan = "Pro Plan (billed monthly)";
    } else {
      currentPlan = "Free Plan";
    }

    return (
      <div className="col-sm-9">
        <div className="border">
          <h3 className="mt-2 mb-3" style={{ fontWeight: 600 }}>
            Current Plan
          </h3>
          <div className="billing-details">
            <div className="plan">
              <div className="label">Current plan:</div>
              <div className="value">{currentPlan}</div>
            </div>
            {stripeSubscribed || isLifetime ? (
              <>
                <div className="next-billing">
                  <div className="label">You have everything 👇:</div>
                </div>
                <div className="wrapper">
                  <div className="content ml-0">
                    <div className="row pt-3 pt-lg-3">
                      <div className="col-12">
                        <ul className="list mb-0">
                          {featureList.map((item) => (
                            <li className="d-flex mb-2 pb-2" key={item.id}>
                              <span className="icon">
                                <img src={checkedIcon} alt="" />
                              </span>
                              <div className="pl-2">
                                <p className="font-size-md mb-0">{item.text}</p>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="next-billing">
                  <div className="label">
                    Submission usage{" "}
                    <span className="text-muted" style={{ fontSize: "12px" }}>
                      (month to date)
                    </span>
                    :
                  </div>
                  <div className="value">
                    <div className="usage-quota">
                      {currentUser.usage || 0}/50
                    </div>
                  </div>
                </div>

                <div className="next-billing">
                  <div className="label feature-highlight">
                    Need custom domain and whitelabel?
                  </div>{" "}
                  👉
                  <div className="value ml-2">
                    <span
                      className="upgrade-cta-btn"
                      onClick={this.props.onUpgrade}
                    >
                      <i className="fas fa-rocket"></i> Upgrade
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PlanSettings);
