import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";
import { toast } from "react-toastify";

import "./Apps.scss";
import "./css/materialdesignicons.min.css";
import "./css/colors/default.css";

// Include Routes
import Saas from "./pages/Saas/index";
import Profile from "./pages/Profile/Profile";
import Settings from "./pages/Settings/Settings";
import Dashboard from "./pages/Dashboard/Dashboard";
import Feature from "./pages/Feature/Feature";
import Pricing from "./pages/Pricing/Pricing";
import Login from "./pages/Login/Login";
import Privacy from "./pages/Privacy/Privacy";
import Terms from "./pages/Terms/Terms";
import PageError from "./pages/PageError/PageError";

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends React.Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

toast.configure();
class App extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    return (
      <React.Fragment>
        <Router>
          <Suspense fallback={this.Loader()}>
            <Switch>
              <Route
                exact
                path="/"
                component={(props) => (
                  <Layout>
                    <Saas {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/profile"
                component={(props) => (
                  <Layout>
                    <Profile {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/settings"
                component={(props) => (
                  <Layout>
                    <Settings {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/features"
                component={(props) => (
                  <Layout>
                    <Feature {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/pricing"
                component={(props) => (
                  <Layout>
                    <Pricing {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/dashboard"
                component={(props) => (
                  <Layout>
                    <Dashboard {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/login"
                component={(props) => (
                  <Layout>
                    <Login {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/privacy"
                component={(props) => (
                  <Layout>
                    <Privacy {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/terms"
                component={(props) => (
                  <Layout>
                    <Terms {...props} />
                  </Layout>
                )}
              />
              <Route
                exact
                path="/error"
                component={(props) => (
                  <Layout>
                    <PageError {...props} />
                  </Layout>
                )}
              />
              <Route
                component={(props) => (
                  <Layout>
                    <PageError {...props} />
                  </Layout>
                )}
              />
            </Switch>
          </Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
