import React from "react";
import { Button, Modal, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { setUserValue } from "../../redux/action/userAction";

class NewSubscriptionModal extends React.Component {
  handleModalClose = () => {
    this.props.setUserValue({ showUpgradeModal: false });
  };

  goToProfilePage = () => {
    this.props.setUserValue({ showUpgradeModal: false });
    this.props.history.push({
      pathname: "/profile",
      state: { tabOption: "billing" },
    });
  };

  render() {
    const { showUpgradeModal } = this.props.user;

    return (
      <Modal show={showUpgradeModal} onHide={this.handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to add your projects</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please go to profile page to complete your subscription. You should
            see a Billing tab on the left side.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary px-5" onClick={this.goToProfilePage}>
            Go upgrade
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NewSubscriptionModal);
