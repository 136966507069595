import React, { Fragment } from "react";
import { withRouter } from "react-router";
import "./Feature.css";
import FeatureSet from "../Saas/Start";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 50) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <Fragment>
        <section className="feature-container">
          <div class="bg-gray-50">
            <div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
              <h2 class="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                All features included in our FREE plan 😱
                <br />
                <span class="text-indigo-600">Get started today.</span>
              </h2>
              <div class="mt-8 flex lg:flex-shrink-0 lg:mt-0">
                <div class="inline-flex rounded-md shadow">
                  <a
                    href="/pricing"
                    class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>

          <FeatureSet />
        </section>
      </Fragment>
    );
  }
}

export default withRouter(Feature);
