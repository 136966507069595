import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import "firebase/analytics";
import { Provider } from "react-redux";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import configureStore from "./store";
import "bootstrap/dist/css/bootstrap.min.css";

const store = configureStore();

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

var firebaseConfig = {
  apiKey: "AIzaSyD_yJkv8asG39b5Sjs1hnCFQgDQEJQH5QE",
  authDomain: "backlogsco.firebaseapp.com",
  databaseURL: "https://backlogsco.firebaseio.com",
  projectId: "backlogsco",
  storageBucket: "backlogsco.appspot.com",
  messagingSenderId: "661332589868",
  appId: "1:661332589868:web:6c871e4d2f644ed4724089",
  measurementId: "G-GVH7SSGNFH",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
