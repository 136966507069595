// React Basic and Bootstrap
import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Import images
import dashboardImg from "../../images/saas/dashboard.png";
import userContributionImg from "../../images/saas/user-contribution.png";
import allFeedbackImg from "../../images/saas/all-feedback.png";
import userUpdateImg from "../../images/saas/user-update.png";
import jsWidget from "../../images/saas/js-widget.png";
import changelogImg from "../../images/saas/changelog.png";
//Import Components
import SectionTitleLeft from "../../components/Shared/section-title-left";

class Start extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features1: [
        { title: "Users can request new features or report bugs" },
        { title: "Easily see what users want most" },
        { title: "Sort by time or by votes" },
      ],
      features2: [
        { title: "Talk with your users to make things clear" },
        { title: "Allow users themselves to exchange thoughts" },
      ],
      features3: [
        { title: "Have your users feel they are taken care of" },
        { title: "Notifications are sent automatically" },
      ],
      features4: [
        { title: "You can have unlimited projects" },
        { title: "The statistics are updated in real-time" },
      ],
      features5: [
        {
          title: "Let your users know you are constantly improving the product",
        },
        { title: "New features and bug fixes won't be unaware" },
      ],
      features6: [
        { title: "Your users know where to submit their feedback" },
        { title: "Easy to embed to any of your projects" },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        <div class="py-6 overflow-hidden">
          <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div class="relative">
                <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Manage all feedback in one place
                </h4>
                <p class="mt-3 text-lg leading-7 text-gray-500">
                  Keep all feedback in a single place. No more digging through
                  emails, private messages to find fragmented information.
                </p>

                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        Users can request new features or report bugs
                      </h5>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        Easily see what users want most
                      </h5>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        Sort by time or by votes
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0 shadow">
                <svg
                  class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        class="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  class="relative mx-auto"
                  width="650"
                  src={allFeedbackImg}
                  alt=""
                />
              </div>
            </div>

            <div class="relative mt-12 sm:mt-16 lg:mt-24">
              <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="lg:col-start-2">
                  <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                    Have your users contribute their ideas
                  </h4>
                  <p class="mt-3 text-lg leading-7 text-gray-500">
                    Your product is made for your users. Give them a chance to
                    ask for features or report bugs. Let them be part of your
                    product journey.
                  </p>

                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          Talk with your users to make things clear
                        </h5>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          Allow users themselves to exchange thoughts
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 shadow">
                  <svg
                    class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    />
                  </svg>
                  <img
                    class="relative mx-auto"
                    width="650"
                    src={userContributionImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div class="relative">
                <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Keep all voters updated
                </h4>
                <p class="mt-3 text-lg leading-7 text-gray-500">
                  When the status of a feedback got changed, all voters will get
                  the email notification automatically.
                </p>

                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900 ">
                        Have your users feel they are taken care of
                      </h5>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        Notifications are sent automatically
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0 shadow">
                <svg
                  class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        class="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  class="relative mx-auto"
                  width="650"
                  src={userUpdateImg}
                  alt=""
                />
              </div>
            </div>

            <div class="relative mt-12 sm:mt-16 lg:mt-24">
              <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="lg:col-start-2">
                  <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                    Monitor all your projects
                  </h4>
                  <p class="mt-3 text-lg leading-7 text-gray-500">
                    Have a dashboard to monitor the feedback statistics of all
                    your projects. If pending percentage is high, it's time to
                    take actions.
                  </p>

                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          You can have unlimited projects
                        </h5>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          Recent activities board to show all notifications
                        </h5>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          The statistics are updated in real-time
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 shadow">
                  <svg
                    class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    />
                  </svg>
                  <img
                    class="relative mx-auto"
                    width="650"
                    src={dashboardImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div class="relative">
                <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Keep your users updated with Changelog
                </h4>
                <p class="mt-3 text-lg leading-7 text-gray-500">
                  Share every progress you made with your users, whether it's a
                  bug fix or new feature.
                </p>

                <ul class="mt-10">
                  <li>
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        Let your users know you are constantly improving the
                        product
                      </h5>
                    </div>
                  </li>
                  <li class="mt-10">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <svg
                          class="h-6 w-6 text-green-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                      </div>
                      <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                        New features and bug fixes won't be unaware
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="mt-10 -mx-4 relative lg:mt-0 shadow">
                <svg
                  class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        class="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  />
                </svg>
                <img
                  class="relative mx-auto"
                  width="650"
                  src={changelogImg}
                  alt=""
                />
              </div>
            </div>

            <div class="relative mt-12 sm:mt-16 lg:mt-24">
              <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div class="lg:col-start-2">
                  <h4 class="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                    Ready to use embeddable widget
                  </h4>
                  <p class="mt-3 text-lg leading-7 text-gray-500">
                    Add this little js widget to your site so that your users
                    know where to submit their feedback.
                  </p>

                  <ul class="mt-10">
                    <li>
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          Your users know where to submit their feedback
                        </h5>
                      </div>
                    </li>
                    <li class="mt-10">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <svg
                            class="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                        <h5 class="ml-2 text-lg leading-6 font-medium text-gray-900">
                          Easy to embed to any of your projects
                        </h5>
                      </div>
                    </li>
                  </ul>
                </div>

                <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 shadow">
                  <svg
                    class="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          class="text-gray-200"
                          fill="currentColor"
                        />
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    />
                  </svg>
                  <img
                    class="relative mx-auto"
                    width="650"
                    src={jsWidget}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Start;
