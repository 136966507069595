// React Basic and Bootstrap
import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

// Import images
import getFeedback from "../../images/icon/getFeedback.svg";
import makeThingsClear from "../../images/icon/makeThingsClear.svg";
import getItDone from "../../images/icon/getItDone.svg";

// Import Generic Components
import Section from "./section";
import SectionTitle from "../../components/Shared/section-title";
import Partners from "../../components/Shared/Partners";
import Feature from "../../components/Shared/Feature";
import Pricing from "../../components/Shared/pricing-box2";
import Start from "./Start";
import Client from "./Client";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricings1: [
        {
          id: 1,
          title: "Free Forever",
          price: 0,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
        {
          id: 2,
          isActive: true,
          title: "Lifetime",
          plan: "lifetime",
          price: 199,
          duration: "",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Lifetime access", highlight: true },
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
        {
          id: 3,
          title: "Pro Monthly",
          plan: "month",
          price: 29,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
      ],
      pricings2: [
        {
          id: 1,
          title: "Free Forever",
          price: 0,
          duration: "mo",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
        {
          id: 2,
          title: "Lifetime",
          plan: "lifetime",
          price: 199,
          duration: "",
          isActive: true,
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "Lifetime access", highlight: true },
            { title: "Unlimited projects", highlight: true },
            { title: "Whitelabel", highlight: true },
            { title: "Custom Domain", highlight: true },
            { title: "+ Everything free plan covers" },
          ],
        },
        {
          id: 3,
          title: "Pro Yearly",
          price: 249,
          duration: "year",
          buttonText: "Get started",
          btnLink: "",
          features: [
            { title: "One project" },
            { title: "Unlimited users" },
            { title: "50 submissions per month" },
            { title: "Unlimited changelog entries" },
            { title: "All core features" },
          ],
        },
      ],
      activeTab: "2",
    };
    this.toggleTab = this.toggleTab.bind(this);
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);

    var featureBox = document.getElementsByClassName("features");
    for (var i = 0; i < featureBox.length; i++) {
      featureBox[i].classList.remove("mt-5");
    }

    var sectionTitles = document.getElementsByName("maintitle");
    sectionTitles[0].classList.remove("mb-4");
    sectionTitles[0].classList.remove("pb-2");
    sectionTitles[0].classList.add("mb-60");
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    const featureArray = [
      {
        id: 1,
        title: "1. Get feedback",
        description:
          "Allow your users to ask for new features, report bugs, or provide other feedback. All feedback will be placed in the Roadmap page.",
        imgUrl: getFeedback,
      },
      {
        id: 2,
        title: "2. Make things clear",
        description:
          "Talk to your users to understand more details. Then update the status to planned or ongoing accordingly to show your commitment.",
        imgUrl: makeThingsClear,
      },
      {
        id: 3,
        title: "3. Get it done",
        description:
          "Once feedback request is completed, annouce to all voters about the latest news. Also add the newest update to the Changelog page.",
        imgUrl: getItDone,
      },
    ];

    return (
      <React.Fragment>
        {/* section */}
        <Section />

        {/* Partner */}
        {/* <section className="section bg-light mt-0 mt-md-5">
          <Container>
            <SectionTitle
              title="Make a better product"
              desc="These awesome products are using Backlogs. Add yours?"
            />

            <Partners />
          </Container>
        </section> */}

        {/* Feature */}
        <section className="section">
          <Container>
            <Feature featureArray={featureArray} isCenter={true} />
          </Container>

          {/* Start */}
          <Start />

          <Container className="mt-100" id="pricing">
            {/* Render Section Title */}
            <SectionTitle
              title="Simple Pricing"
              desc="We aim to serve better for our indie hackers community 💪. We offer free plan for all with all core features. For the Pro plan, you can get extra control on using your own branding."
            />

            <Row className="align-items-center">
              <Col xs="12" className="mt-2 ">
                <div className="text-center">
                  <Nav
                    pills
                    className="rounded-pill justify-content-center d-inline-block border py-1 px-2"
                  >
                    <NavItem className="d-inline-block mr-1">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === "1" },
                          "px-3",
                          "rounded-pill",
                          "monthly"
                        )}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        Monthly
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-inline-block">
                      <NavLink
                        className={classnames(
                          { active: this.state.activeTab === "2" },
                          "px-3",
                          "rounded-pill",
                          "yearly"
                        )}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Yearly 🤩
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={this.state.activeTab} className="mt-5">
                  <TabPane tabId="1">
                    <div className="align-items-center">
                      {/* pricing */}
                      <Pricing pricings={this.state.pricings1} />
                    </div>
                  </TabPane>

                  <TabPane tabId="2">
                    <div className="align-items-center">
                      {/* pricing */}
                      <Pricing pricings={this.state.pricings2} />
                    </div>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>

          {/* Client */}
          <Client />
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
