import React, { useMemo, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import firebase from "firebase/app";
import "firebase/functions";
import PulseLoader from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import "./StripeForm.css";
import useResponsiveFontSize from "./useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CardForm = ({ plan, customer }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const customerId = customer.stripeId;

  const stripeCreateOnetimePayment = (cardElement, priceId) => {
    setLoading(true);
    try {
      var createPaymentIntent = firebase
        .functions()
        .httpsCallable("stripeCreatePaymentIntent");

      createPaymentIntent().then(async (paymentIntent) => {
        const clientSecret = paymentIntent.data.clientSecret;

        stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardElement,
            },
          })
          .then(async (result) => {
            if (result.error) {
              // Show error to your customer
              toast.error(
                "Oops, the payment failed. You may try another card.",
                {
                  autoClose: 5000,
                }
              );
              setLoading(false);
            } else {
              // The payment succeeded!
              await firebase
                .database()
                .ref("users")
                .child(customer.uid)
                .update({
                  plan: "lifetime",
                  paymentIntent: result.paymentIntent,
                });
              toast.success(
                "You just purchased our lifetime plan successfully.",
                {
                  autoClose: 3000,
                }
              );
              setLoading(false);
            }
          });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const stripeCreatePaymentMethod = (cardElement, priceId) => {
    setLoading(true);
    try {
      stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement,
        })
        .then(function (result) {
          if (result.error) {
            if (result.error.code === "card_declined") {
              toast.error(
                "Oops, your card was declined 😢. Would you mind trying another card one more time?",
                {
                  autoClose: 5000,
                }
              );
            } else {
              toast.error(
                "Oops, the payment failed. You may try another card.",
                {
                  autoClose: 5000,
                }
              );
            }
            setLoading(false);
          } else {
            stripeCreateSubscription({
              paymentMethodId: result.paymentMethod.id,
              priceId: priceId,
            });
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const stripeCreateSubscription = ({ paymentMethodId, priceId }) => {
    try {
      var stripeCreateSubscription = firebase
        .functions()
        .httpsCallable("stripeCreateSubscription");
      stripeCreateSubscription({
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
      }).then(async (subscription) => {
        if (subscription.data.status === "active") {
          await firebase.database().ref("users").child(customer.uid).update({
            stripeSubscription: subscription.data,
          });
          toast.success(
            "You subscribed to our " + plan + " plan successfully.",
            {
              autoClose: 3000,
            }
          );
        } else if (
          subscription.data.latest_invoice.payment_intent.status ===
          "requires_payment_method"
        ) {
          toast.error("Oops, your card was declined.", {
            autoClose: 3000,
          });
        }
        setLoading(false);
      });
    } catch (error) {
      console.log("2. stripe setupIntent failed: ", error);
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    var priceId = "";
    if (plan === "monthly") {
      priceId = "price_1H5gmwKIF9S36ViQ64QriHFv";
    } else if (plan === "yearly") {
      priceId = "price_1H5ejAKIF9S36ViQj8s0MERu";
    } else {
      // plan === "lifetime"
      priceId = "price_1HCHmzKIF9S36ViQryaKGdJo";
    }

    // create stripe payment method
    if (plan === "lifetime") {
      // one-time payment
      stripeCreateOnetimePayment(elements.getElement(CardElement), priceId);
    } else {
      // subscription
      stripeCreatePaymentMethod(elements.getElement(CardElement), priceId);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={options}
        onReady={() => {
          // console.log("CardElement [ready]");
        }}
        onChange={(event) => {
          // console.log("CardElement [change]", event);
        }}
        onBlur={() => {
          // console.log("CardElement [blur]");
        }}
        onFocus={() => {
          // console.log("CardElement [focus]");
        }}
      />
      <div className="text-center border-less">
        <div className="col-12">
          <button type="submit" className="btn btn-lg btn-primary mt-2">
            {loading ? (
              <PulseLoader sizeUnit={"px"} size={8} color={"#fff"} />
            ) : (
              "Subscribe now"
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CardForm;
