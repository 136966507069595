// React Basic and Bootstrap
import React, { Component } from "react";
import { Row, Col, Card, CardBody, Collapse } from "reactstrap";
import "./Privacy.css";

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      t1: true,
      t2: false,
      t3: false,
      t4: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  render() {
    return (
      <React.Fragment>
        <main className="bg-light privacy-container">
          <div className="main">
            <div className="container mt-5 px-5">
              <Row className="justify-content-center mb-5">
                <Col lg={12} className="text-center">
                  <div className="page-next-level">
                    <h4 className="title"> Privacy Policy </h4>
                    <p className="text-muted">Last updated: June 22, 2020</p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <div className="p-4 shadow border rounded">
                    <p className="text-muted">
                      At Backlogs, we care deeply about your privacy. We believe
                      that privacy is a fundamental human right, and this belief
                      is ingrained in our product and our processes.
                    </p>
                    <p className="text-muted">
                      We strive to be as transparent as possible with how we
                      handle your data. If you have any questions, don't
                      hesitate to contact us.
                    </p>
                    <h5>1. Introduction</h5>
                    <p className="text-muted">
                      Lonely Dev (“us”, “we”, or “our”) operates
                      https://backlogs.co (hereinafter referred to as
                      “Service”).
                    </p>
                    <p className="text-muted">
                      Our Privacy Policy governs your visit to
                      https://backlogs.co, and explains how we collect,
                      safeguard and disclose information that results from your
                      use of our Service.
                    </p>
                    <p className="text-muted">
                      We use your data to provide and improve Service. By using
                      Service, you agree to the collection and use of
                      information in accordance with this policy. Unless
                      otherwise defined in this Privacy Policy, the terms used
                      in this Privacy Policy have the same meanings as in our
                      Terms and Conditions.
                    </p>
                    <p className="text-muted">
                      Our Terms and Conditions (“Terms”) govern all use of our
                      Service and together with the Privacy Policy constitutes
                      your agreement with us (“agreement”).
                    </p>

                    <h5>2. Definitions</h5>
                    <p className="text-muted">
                      SERVICE means the https://backlogs.co website operated by
                      Backlogs.
                    </p>
                    <p className="text-muted">
                      PERSONAL DATA means data about a living individual who can
                      be identified from those data (or from those and other
                      information either in our possession or likely to come
                      into our possession).
                    </p>
                    <p className="text-muted">
                      USAGE DATA is data collected automatically either
                      generated by the use of Service or from Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </p>
                    <p className="text-muted">
                      COOKIES are small files stored on your device (computer or
                      mobile device).
                    </p>
                    <p className="text-muted">
                      DATA CONTROLLER means a natural or legal person who
                      (either alone or jointly or in common with other persons)
                      determines the purposes for which and the manner in which
                      any personal data are, or are to be, processed. For the
                      purpose of this Privacy Policy, we are a Data Controller
                      of your data.
                    </p>
                    <p className="text-muted">
                      DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural
                      or legal person who processes the data on behalf of the
                      Data Controller. We may use the services of various
                      Service Providers in order to process your data more
                      effectively.
                    </p>
                    <p className="text-muted">
                      DATA SUBJECT is any living individual who is the subject
                      of Personal Data.
                    </p>
                    <p className="text-muted">
                      THE USER is the individual using our Service. The User
                      corresponds to the Data Subject, who is the subject of
                      Personal Data.
                    </p>

                    <h5>3. Information Collection and Use</h5>
                    <p className="text-muted">
                      We collect several different types of information for
                      various purposes to provide and improve our Service to
                      you.
                    </p>
                    <h5>4. Types of Data Collected</h5>
                    <h5>Personal Data</h5>
                    <p className="text-muted">
                      While using our Service, we may ask you to provide us with
                      certain personally identifiable information that can be
                      used to contact or identify you (“Personal Data”).
                      Personally identifiable information may include, but is
                      not limited to:
                    </p>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        Email address
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>First
                        name and last name
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        Cookies and Usage Data
                      </li>
                    </ul>
                    <p className="text-muted">
                      We may use your Personal Data to contact you with
                      newsletters, marketing or promotional materials and other
                      information that may be of interest to you. You may opt
                      out of receiving any, or all, of these communications from
                      us by following the unsubscribe link.
                    </p>
                    <h5>Usage Data</h5>
                    <p className="text-muted">
                      We may also collect information that your browser sends
                      whenever you visit our Service or when you access Service
                      by or through a mobile device (“Usage Data”).
                    </p>
                    <p className="text-muted">
                      This Usage Data may include information such as your
                      computer's Internet Protocol address (e.g. IP address),
                      browser type, browser version, the pages of our Service
                      that you visit, the time and date of your visit, the time
                      spent on those pages, unique device identifiers and other
                      diagnostic data.
                    </p>
                    <p className="text-muted">
                      When you access Service with a mobile device, this Usage
                      Data may include information such as the type of mobile
                      device you use, your mobile device unique ID, the IP
                      address of your mobile device, your mobile operating
                      system, the type of mobile Internet browser you use,
                      unique device identifiers and other diagnostic data.
                    </p>
                    <h5>Tracking Cookies Data</h5>
                    <p className="text-muted">
                      We use cookies and similar tracking technologies to track
                      the activity on our Service and we hold certain
                      information.
                    </p>
                    <p className="text-muted">
                      Cookies are files with a small amount of data which may
                      include an anonymous unique identifier. Cookies are sent
                      to your browser from a website and stored on your device.
                      Other tracking technologies are also used such as beacons,
                      tags and scripts to collect and track information and to
                      improve and analyze our Service.
                    </p>
                    <p className="text-muted">
                      You can instruct your browser to refuse all cookies or to
                      indicate when a cookie is being sent. However, if you do
                      not accept cookies, you may not be able to use some
                      portions of our Service.
                    </p>
                    <p className="text-muted">Examples of Cookies we use:</p>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        Session Cookies: We use Session Cookies to operate our
                        Service.
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        Preference Cookies: We use Preference Cookies to
                        remember your preferences and various settings.
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        Security Cookies: We use Security Cookies for security
                        purposes.
                      </li>
                    </ul>

                    <h5>5. Use of Data</h5>
                    <p className="text-muted">
                      Backlogs uses the collected data for various purposes:
                    </p>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to provide and maintain our Service;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to notify you about changes to our Service;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to allow you to participate in interactive features of
                        our Service when you choose to do so;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to provide customer support;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to gather analysis or valuable information so that we
                        can improve our Service;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to monitor the usage of our Service;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to detect, prevent and address technical issues;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to fulfill any other purpose for which you provide it;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to carry out our obligations and enforce our rights
                        arising from any contracts entered into between you and
                        us, including for billing and collection;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to provide you with notices about your account and/or
                        subscription, including expiration and renewal notices,
                        email-instructions, etc.;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to provide you with news, special offers and general
                        information about other goods, services and events which
                        we offer that are similar to those that you have already
                        purchased or enquired about unless you have opted not to
                        receive such information;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        in any other way we may describe when you provide the
                        information;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        for any other purpose with your consent.
                      </li>
                    </ul>
                    <h5>6. Retention of Data</h5>
                    <p className="text-muted">
                      We will retain your Personal Data only for as long as is
                      necessary for the purposes set out in this Privacy Policy.
                      We will retain and use your Personal Data to the extent
                      necessary to comply with our legal obligations (for
                      example, if we are required to retain your data to comply
                      with applicable laws), resolve disputes, and enforce our
                      legal agreements and policies.
                    </p>
                    <p className="text-muted">
                      We will also retain Usage Data for internal analysis
                      purposes. Usage Data is generally retained for a shorter
                      period, except when this data is used to strengthen the
                      security or to improve the functionality of our Service,
                      or we are legally obligated to retain this data for longer
                      time periods.
                    </p>
                    <h5>7. Transfer of Data</h5>
                    <p className="text-muted">
                      Your information, including Personal Data, may be
                      transferred to – and maintained on – computers located
                      outside of your state, province, country or other
                      governmental jurisdiction where the data protection laws
                      may differ from those of your jurisdiction.
                    </p>
                    <p className="text-muted">
                      If you are located outside United States and choose to
                      provide information to us, please note that we transfer
                      the data, including Personal Data, to United States and
                      process it there.
                    </p>
                    <p className="text-muted">
                      Your consent to this Privacy Policy followed by your
                      submission of such information represents your agreement
                      to that transfer.
                    </p>
                    <p className="text-muted">
                      Backlogs will take all the steps reasonably necessary to
                      ensure that your data is treated securely and in
                      accordance with this Privacy Policy and no transfer of
                      your Personal Data will take place to an organisation or a
                      country unless there are adequate controls in place
                      including the security of your data and other personal
                      information.
                    </p>
                    <h5>8. Disclosure of Data</h5>
                    <p className="text-muted">
                      We may disclose personal information that we collect, or
                      you provide:
                    </p>
                    <h5>Disclosure for Law Enforcement.</h5>
                    <p className="text-muted">
                      Under certain circumstances, we may be required to
                      disclose your Personal Data if required to do so by law or
                      in response to valid requests by public authorities.
                    </p>
                    <h5>Business Transaction.</h5>
                    <p className="text-muted">
                      If we or our subsidiaries are involved in a merger,
                      acquisition or asset sale, your Personal Data may be
                      transferred.
                    </p>
                    <h5>Other cases. We may disclose your information also:</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to our subsidiaries and affiliates;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to contractors, service providers, and other third
                        parties we use to support our business;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to fulfill the purpose for which you provide it;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        for the purpose of including your company’s logo on our
                        website;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        for any other purpose disclosed by us when you provide
                        the information;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        with your consent in any other cases;
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        if we believe disclosure is necessary or appropriate to
                        protect the rights, property, or safety of the Company,
                        our customers, or others.
                      </li>
                    </ul>
                    <h5>9. Security of Data</h5>
                    <p className="text-muted">
                      The security of your data is important to us but remember
                      that no method of transmission over the Internet or method
                      of electronic storage is 100% secure. While we strive to
                      use commercially acceptable means to protect your Personal
                      Data, we cannot guarantee its absolute security.
                    </p>
                    <h5>10. "Do Not Track" Signals</h5>
                    <p className="text-muted">
                      We do not support Do Not Track ("DNT"). Do Not Track is a
                      preference you can set in your web browser to inform
                      websites that you do not want to be tracked.
                    </p>
                    <p className="text-muted">
                      You can enable or disable Do Not Track by visiting the
                      Preferences or Settings page of your web browser.
                    </p>
                    <h5>10. Your Rights</h5>
                    <p className="text-muted">
                      Lonely Dev Inc aims to take reasonable steps to allow you
                      to correct, amend, delete, or limit the use of your
                      Personal Data.
                    </p>
                    <p className="text-muted">
                      Whenever made possible, you can update your Personal Data
                      directly within your account settings section. If you are
                      unable to change your Personal Data, please contact us to
                      make the required changes.
                    </p>
                    <p className="text-muted">
                      If you wish to be informed what Personal Data we hold
                      about you and if you want it to be removed from our
                      systems, please contact us.
                    </p>
                    <p className="text-muted">
                      In certain circumstances, you have the right:
                    </p>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to access and receive a copy of the Personal Data we
                        hold about you
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to rectify any Personal Data held about you that is
                        inaccurate
                      </li>
                      <li>
                        <i className="mdi mdi-pan-right mdi-24px mr-2"></i>
                        to request the deletion of Personal Data held about you
                      </li>
                    </ul>
                    <p className="text-muted">
                      You have the right to data portability for the information
                      you provide to Lonely Dev Inc. You can request to obtain a
                      copy of your Personal Data in a commonly used electronic
                      format so that you can manage and move it.
                    </p>
                    <p className="text-muted">
                      Please note that we may ask you to verify your identity
                      before responding to such requests.
                    </p>
                    <h5>11. Service Providers</h5>
                    <p className="text-muted">
                      We may employ third party companies and individuals to
                      facilitate our Service (“Service Providers”), provide
                      Service on our behalf, perform Service-related services or
                      assist us in analysing how our Service is used.
                    </p>
                    <p className="text-muted">
                      These third parties have access to your Personal Data only
                      to perform these tasks on our behalf and are obligated not
                      to disclose or use it for any other purpose.
                    </p>
                    <h5>12. Analytics</h5>
                    <p className="text-muted">
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </p>
                    <h5>Google Analytics</h5>
                    <p className="text-muted">
                      Google Analytics is a web analytics service offered by
                      Google that tracks and reports website traffic. Google
                      uses the data collected to track and monitor the use of
                      our Service. This data is shared with other Google
                      services. Google may use the collected data to
                      contextualize and personalize the ads of its own
                      advertising network.
                    </p>
                    <p className="text-muted">
                      You can opt-out of having made your activity on the
                      Service available to Google Analytics by installing the
                      Google Analytics opt-out browser add-on. The add-on
                      prevents the Google Analytics JavaScript (ga.js,
                      analytics.js, and dc.js) from sharing information with
                      Google Analytics about visits activity.
                    </p>
                    <p className="text-muted">
                      For more information on the privacy practices of Google,
                      please visit the Google Privacy & Terms web page.
                    </p>

                    <h5>13. Payments</h5>
                    <p className="text-muted">
                      We may provide paid products and/or services within the
                      Service. In that case, we use third-party services for
                      payment processing (e.g. payment processors).
                    </p>
                    <p className="text-muted">
                      We will not store or collect your payment card details.
                      That information is provided directly to our third-party
                      payment processors whose use of your personal information
                      is governed by their Privacy Policy. These payment
                      processors adhere to the standards set by PCI-DSS as
                      managed by the PCI Security Standards Council, which is a
                      joint effort of brands like Visa, Mastercard, American
                      Express and Discover. PCI-DSS requirements help ensure the
                      secure handling of payment information.
                    </p>
                    <p className="text-muted">
                      The payment processor we work with is Stripe. Their
                      Privacy Policy can be viewed here.
                    </p>
                    <h5>14. Transactional Emails</h5>
                    <p className="text-muted">
                      We may send you transactional emails if you use any
                      products and/or services within Service. We use the
                      Mailgun as the provider to send you transactional emails.
                    </p>
                    <h5>15. Links to Other Sites</h5>
                    <p className="text-muted">
                      Our Service may contain links to other sites that are not
                      operated by us. If you click a third party link, you will
                      be directed to that third party's site. We strongly advise
                      you to review the Privacy Policy of every site you visit.
                    </p>
                    <p className="text-muted">
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>
                    <h5>16. Children's Privacy</h5>
                    <p className="text-muted">
                      Our Service does not address anyone under the age of 13
                      ("Children").
                    </p>
                    <p className="text-muted">
                      We do not knowingly collect personally identifiable
                      information from anyone under the age of 13. If you are a
                      parent or guardian and you are aware that your Children
                      has provided us with Personal Data, please contact us. If
                      we become aware that we have collected Personal Data from
                      children without verification of parental consent, we take
                      steps to remove that information from our servers.
                    </p>
                    <h5>17. Changes to This Privacy Policy</h5>
                    <p className="text-muted">
                      We may update our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page.
                    </p>
                    <p className="text-muted">
                      We will let you know via email and/or a prominent notice
                      on our Service, prior to the change becoming effective. We
                      will also update the “effective date” at the top of this
                      Privacy Policy.
                    </p>
                    <p className="text-muted">
                      You are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </p>
                    <h5>18. Changes to This Privacy Policy</h5>
                    <p className="text-muted">
                      If you have any questions about this Privacy Policy,
                      please contact us at damon@lonely.dev.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default Terms;
