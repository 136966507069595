import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";
// Scroll up button
import { TinyButton as ScrollUpButton } from "react-scroll-up-button";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() => import("../../pages/Saas"));
const Footer = React.lazy(() => import("./Footer"));

class Layout extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {this.props.location.pathname === "/index-onepage" ? (
            <NavbarPage />
          ) : (
            <Topbar />
          )}

          {this.props.children}

          <Footer isLight={true} />

          {/* scrollup button */}
          {/* <div id="bottomIcon">
            <ScrollUpButton className="bottomIcon" />
          </div> */}
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
