import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Linkify from "linkifyjs/react";
import { Icon, Popup } from "semantic-ui-react";

class SectionTitle extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className={this.props.isLeft ? "" : "justify-content-center"}>
          <Col xs="12" className={this.props.isLeft ? "" : "text-center"}>
            <div className="section-title mb-4 pt-0 pb-2" name="maintitle">
              <h4 className="title mb-4" name="sectiontitle">
                {this.props.title}
              </h4>
              <p
                className={
                  this.props.isLeft
                    ? "text-muted para-desc mb-0"
                    : "text-muted para-desc mx-auto mb-0"
                }
                name="sectiondesc"
              >
                <Linkify style={{ fontSize: "16px" }}>
                  {this.props.desc}{" "}
                </Linkify>

                {this.props.title === "Pricing" && (
                  <Popup
                    trigger={
                      <Icon name="question circle" color="grey" size="large" />
                    }
                    content="The discount will be paid in the year end as a refund. Weekly update user will get a refund of $240. Monthly update user will get a refund of $120."
                    position="right center"
                  />
                )}
              </p>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SectionTitle;
