import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Icon } from "semantic-ui-react";
import { formatDistance } from "date-fns";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import { toast } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { setUserValue } from "../../redux/action/userAction";
import "./Dashboard.css";
import NewSubscriptionModal from "../../components/Modals/NewSubscriptionModal";
import { objectToArray } from "../../components/Utils/Helpers";

var unsubscribe = null;
var currentUserListener;
var notificationListener;
var needUnmount = true;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      products: [],
      productStats: [],
      notifications: [],
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    document.body.classList = "";
    window.addEventListener("scroll", this.scrollNavigation, true);

    unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        currentUserListener = await firebase
          .database()
          .ref("users")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              user: snapshot.val(),
              products: snapshot.val().companies || [],
              isLoading: false,
            });

            var productStats = [];

            Object.values(snapshot.val().companies).map(async (company) => {
              productStats[company.subdomain] = {};
              productStats[company.subdomain].stats = {};

              firebase
                .database()
                .ref("company-posts")
                .child(company.subdomain)
                .child("active")
                .orderByChild("status")
                .equalTo("PENDING")
                .once("value")
                .then((snapshot) => {
                  productStats[company.subdomain].stats.pending =
                    (snapshot.val() && Object.values(snapshot.val()).length) ||
                    0;

                  this.setState({ productStats: productStats });
                });

              firebase
                .database()
                .ref("company-posts")
                .child(company.subdomain)
                .child("active")
                .orderByChild("status")
                .equalTo("PLANNED")
                .once("value")
                .then((snapshot) => {
                  productStats[company.subdomain].stats.planned =
                    (snapshot.val() && Object.values(snapshot.val()).length) ||
                    0;

                  this.setState({ productStats: productStats });
                });

              firebase
                .database()
                .ref("company-posts")
                .child(company.subdomain)
                .child("completed")
                .once("value")
                .then((snapshot) => {
                  productStats[company.subdomain].stats.completed =
                    (snapshot.val() && Object.values(snapshot.val()).length) ||
                    0;

                  this.setState({ productStats: productStats });
                });

              firebase
                .database()
                .ref("company-posts")
                .child(company.subdomain)
                .child("active")
                .orderByChild("status")
                .equalTo("ONGOING")
                .once("value")
                .then((snapshot) => {
                  productStats[company.subdomain].stats.ongoing =
                    (snapshot.val() && Object.values(snapshot.val()).length) ||
                    0;

                  this.setState({ productStats: productStats });
                });
            });
          });
        notificationListener = firebase
          .database()
          .ref("notifications")
          .child(user.uid)
          .on("value", (snapshot) => {
            this.setState({
              notifications: objectToArray(snapshot.val()) || [],
            });
          });
      } else {
        this.props.history.push("/");
      }
    });
  };

  componentWillUnmount = async () => {
    window.removeEventListener("scroll", this.scrollNavigation, true);

    if (this.state.user) {
      await firebase
        .database()
        .ref("users")
        .child(this.state.user.uid)
        .off("value", currentUserListener);

      await firebase
        .database()
        .ref("notifications")
        .child(this.state.user.uid)
        .off("value", notificationListener);
    }

    unsubscribe && unsubscribe();
  };

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 50) {
      document.getElementById("topnav").classList.add("nav-sticky");
    } else {
      document.getElementById("topnav").classList.remove("nav-sticky");
    }
  };

  onGoToSettings = () => {
    const { currentUser } = this.props.user;
    if (
      (currentUser &&
        currentUser.stripeSubscription &&
        currentUser.stripeSubscription.status === "active") ||
      (currentUser.plan === "lifetime" &&
        currentUser.paymentIntent &&
        currentUser.paymentIntent.status == "succeeded") ||
      !currentUser.companies ||
      (currentUser.companies && Object.keys(currentUser.companies) === 0)
    ) {
      this.props.history.push("settings");
    } else {
      this.props.setUserValue({ showUpgradeModal: true });
    }
  };

  markAllAsRead = async () => {
    const { notifications } = this.state;

    notifications.length > 0 &&
      (await firebase
        .database()
        .ref("notifications")
        .child(this.state.user.uid)
        .remove());

    toast.success("Nice! You cleared all notifications 👏", {
      autoClose: 3000,
    });
  };

  markAsRead = async (notification) => {
    await firebase
      .database()
      .ref("notifications")
      .child(this.state.user.uid)
      .child(notification.id)
      .remove();
  };

  render() {
    const { products, productStats, notifications, isLoading } = this.state;
    const { pendingCount } = this.state;
    const newNotifications = Object.values(notifications).reverse();

    if (isLoading) {
      return (
        <Fragment>
          <section className="dashboard-container">
            <div
              className="text-center"
              style={{ marginTop: "150px", marginBottom: "150px" }}
            >
              <PulseLoader sizeUnit={"px"} size={8} color={"#5850ec"} />
            </div>
          </section>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <section className="dashboard-container">
          <NewSubscriptionModal />

          <div className="fixed top-0 left-0 w-1/2 h-ful bg-white"></div>
          <div className="fixed top-0 right-0 w-1/2 h-full"></div>
          <div className="relative flex flex-col">
            <div className="flex-grow w-full max-w-7xl mx-auto xl:px-8 lg:flex">
              <div className="pr-4 sm:pr-6 lg:pr-8 xl:pr-0 flex-1 min-w-0 bg-white xl:flex">
                <div className="pl-6 bg-white lg:min-w-0 lg:flex-1">
                  <div className="pr-6 pt-4 pb-4 border-b border-gray-200 xl:pt-6 xl:border-t-0">
                    <div className="flex items-center">
                      <h1 className="flex-1 text-xl leading-7 font-medium">
                        {Object.values(products).length} Project
                        {Object.values(products).length < 2 ? "" : "s"}
                      </h1>
                    </div>
                  </div>
                  <ul className="relative z-0 divide-y divide-gray-200 border-b border-gray-200">
                    {Object.values(products).map((product) => {
                      const subdomainURL =
                        product.customDomain !== ""
                          ? product.customDomain
                          : product.subdomain + ".backlogs.co";

                      return (
                        <li
                          className="relative pl-4 pr-6 py-5 hover:bg-gray-50 sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6"
                          key={product.subdomain}
                        >
                          <div className="flex items-center justify-between space-x-4">
                            <div className="min-w-0 space-y-3">
                              <div className="flex items-center space-x-3">
                                <img
                                  src={product.logo}
                                  alt="product logo"
                                  style={{ width: "30px" }}
                                />

                                <span className="block">
                                  <h2 className="text-2xl font-semibold leading-5">
                                    <a
                                      href={"https://" + subdomainURL}
                                      target="_blank"
                                    >
                                      <span className="absolute inset-0"></span>
                                      {product.name}
                                    </a>
                                  </h2>
                                </span>
                              </div>
                              <a
                                href={"https://" + subdomainURL}
                                target="_blank"
                                className="relative group flex items-center space-x-2.5"
                              >
                                <div className="text-lg leading-5 text-gray-500 group-hover:text-gray-900 font-medium truncate">
                                  {subdomainURL}
                                </div>
                              </a>
                            </div>
                            <div className="sm:hidden">
                              <svg
                                className="h-5 w-5 text-gray-400"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>

                            <div className="hidden sm:flex flex-col flex-shrink-0 items-end space-y-3">
                              <p className="flex items-center space-x-4">
                                <a
                                  href={"https://" + subdomainURL}
                                  target="_blank"
                                  className="relative text-lg leading-5 text-gray-500 hover:text-gray-900 font-medium"
                                >
                                  Completed:{" "}
                                  {(productStats[product.subdomain] &&
                                    productStats[product.subdomain].stats
                                      .completed) ||
                                    0}
                                </a>
                              </p>
                              <p className="flex text-gray-500 text-lg leading-5 space-x-2">
                                {product.stats ? (
                                  <>
                                    <span>
                                      {productStats[product.subdomain] &&
                                        productStats[product.subdomain].stats
                                          .pending}{" "}
                                      Pending
                                    </span>
                                    <span>&middot;</span>
                                    <span>
                                      {productStats[product.subdomain] &&
                                        productStats[product.subdomain].stats
                                          .planned}{" "}
                                      Planned
                                    </span>
                                    <span>&middot;</span>
                                    <span>
                                      {productStats[product.subdomain] &&
                                        productStats[product.subdomain].stats
                                          .ongoing}{" "}
                                      Ongoing
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <span>0 Pending</span>
                                    <span>&middot;</span>
                                    <span>0 Planned</span>
                                    <span>&middot;</span>
                                    <span>0 Ongoing</span>
                                  </>
                                )}
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="xl:flex-shrink-0 xl:w-64 bg-white mx-auto">
                    <div className="pl-4 pr-6 py-6 sm:pl-6 lg:pl-8 xl:pl-0">
                      <div className="flex items-center justify-between">
                        <div className="flex-1 space-y-8">
                          <div className="space-y-8 sm:space-y-0 sm:flex sm:justify-between sm:items-center xl:block xl:space-y-8">
                            <div className="flex flex-col space-y-3 sm:space-y-0 sm:space-x-3 sm:flex-row xl:flex-col xl:space-x-0 xl:space-y-3">
                              <span className="inline-flex rounded-md shadow-sm">
                                <button
                                  type="button"
                                  className="w-full inline-flex items-center justify-center px-4 py-3 border border-transparent text-lg leading-5 font-semibold rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                                  onClick={this.onGoToSettings}
                                >
                                  New Project
                                </button>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pr-4 sm:pr-6 lg:pr-8 lg:flex-shrink-0 xl:pr-0">
                <div className="pl-6 lg:w-80">
                  <div className="pt-4 pb-4 border-b border-gray-200 xl:pt-6 xl:border-t-0">
                    <div className="flex items-center">
                      <h1 className="flex-1 text-xl leading-7 font-medium">
                        Recent activities
                      </h1>
                      <div style={{ float: "right", display: "inline" }}>
                        <Tippy
                          content="Mark all as read"
                          placement="right-start"
                        >
                          <div
                            onClick={this.markAllAsRead}
                            className="notification-header-icon"
                            style={{ cursor: "pointer" }}
                          >
                            <Icon
                              name="check square outline"
                              style={{ color: "#565656" }}
                            />
                          </div>
                        </Tippy>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ul className="divide-y divide-gray-200">
                      {newNotifications.map((notification) => {
                        var notificationTitle = "";
                        if (notification.type === "bug") {
                          notificationTitle = "Reported a bug for ";
                        } else if (notification.type === "feature") {
                          notificationTitle = "Requested a feature for ";
                        } else {
                          notificationTitle = "Submitted a feedback for ";
                        }
                        return (
                          <li className="" key={notification.createdAt}>
                            <div className="notification-item flex space-x-3">
                              <img
                                className="h-6 w-6 rounded-full"
                                src={notification.user.avatar}
                                alt=""
                              />
                              <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                  <h3 className="text-lg font-medium leading-5">
                                    {notification.user.name}
                                  </h3>

                                  <Tippy
                                    content="Mark as read"
                                    placement="right-start"
                                  >
                                    <span
                                      className="more-option text-lg leading-5 text-gray-500"
                                      onClick={() =>
                                        this.markAsRead(notification)
                                      }
                                    >
                                      <Icon
                                        name="close"
                                        size="small"
                                        style={{ color: "#565656" }}
                                      />
                                    </span>
                                  </Tippy>
                                </div>
                                <a
                                  href={notification.link}
                                  target="_blank"
                                  className="relative group flex items-center space-x-2.5"
                                >
                                  <div className="text-base leading-5 text-gray-500 group-hover:text-gray-900 font-medium whitespace-pre-line">
                                    {notificationTitle}{" "}
                                    <span style={{ color: "#0779e4" }}>
                                      {notification.project}
                                    </span>
                                  </div>
                                </a>
                                <small className="text-muted">
                                  {formatDistance(
                                    notification.createdAt,
                                    Date.now()
                                  )}{" "}
                                  ago
                                </small>
                              </div>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {newNotifications.length === 0 && (
                      <p className="flex text-gray-500 text-lg leading-5 space-x-2">
                        No activities recently
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state && state.user,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserValue: (state) => {
    return dispatch(setUserValue(state));
  },
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
